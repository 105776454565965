<template>
  <div v-if="exchangeRate && item">
    <div class="pt-2 px-2">
      <h2>{{ item ? item.name : '' }}</h2>
    </div>
    <validation-observer ref="addEditExchangeRateForm">
      <b-form class="mt-2" @submit.prevent="addUpdateExchageRate">
        <b-container>
          <b-row>
            <b-col md="12">
              <b-form-group label="Tasa de cambio (venta)">
                <validation-provider
                  #default="{ errors }"
                  name="Tasa de cambio (venta)"
                  rules="required"
                >
                  <b-form-input
                    v-model="exchangeRate.sale"
                    :state="errors.length > 0 ? false : null"
                    name="exchange_rate_sale"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Tasa de cambio (compra)">
                <validation-provider
                  #default="{ errors }"
                  name="Tasa de cambio (compra)"
                  rules="required"
                >
                  <b-form-input
                    v-model="exchangeRate.buy"
                    :state="errors.length > 0 ? false : null"
                    name="exchange_rate_buy"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div
                class="d-flex justify-content-center border-top align-items-center py-5"
              >
                <b-button :disabled="loading" type="submit" variant="primary">{{ loading ? 'Guardando...' : 'Guardar' }}</b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BCard,
  VBToggle,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

const initData = {
  buy: '',
  sale: ''
}


export default {
  components: {
    BContainer,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    vSelect,
    BCard,
    FeatherIcon,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    code: {
      type: String,
      default: null,
    },
    paymentMethodId: {
      type: String,
      default: null,
    },
    currencyId: {
      type: String,
      default: null,
    },
    namespaceModel: {
      type: String,
      default: null,
    },
    modelId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      exchangeRate: JSON.parse(JSON.stringify(initData)),
      loading: false,
    }
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    addUpdateExchageRate() {
        this.$refs.addEditExchangeRateForm.validate().then( async (success) => {
            if (success) {
                let data = {
                  ...this.exchangeRate,
                  code: this.code,
                  id: this.item.id,
                  payment_method_id: this.paymentMethodId,
                  currency_id: this.currencyId,
                  namespace_model : this.namespaceModel,
                  model_id: this.modelId,
                }

                // if (this.item) {
                //     data = { ...this.exchangeRate, code: this.code, id: this.item.id }
                // }

                this.loading = true
                const res = await this.$store.dispatch('paymentOptionAccounts/saveExchangeRate', data)
                this.loading = false
                if (res?.success) {
                    this.$emit('saved', res.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.message,
                            icon: 'CheckCircleIcon',
                            variant: 'success',
                        },
                    })
                } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.message,
                            icon: 'CheckCircleIcon',
                            variant: 'success',
                        },
                    })
                }
            }
        })
    },
  },
  watch: {
    item: {
      handler: function (val) {
        this.exchangeRate = JSON.parse(JSON.stringify((val ? { buy: val.rate_buy, sale: val.rate_sale } : JSON.parse(JSON.stringify(initData)))))
      },
      deep: true,
    },
  },
  mounted() {},
}
</script>
