<template>
  <b-card>
    <b-row class="pb-1">
      <b-col md="3" cols="6">
        <b-form-input
          v-model="searchValue"
          placeholder="Buscar"
          debounce="400"
        />
      </b-col>
      <!-- <b-col md="9" cols="6" class="text-right">
        <b-button variant="primary" class="px-1" @click="create()">
          <feather-icon size="1.2x" icon="PlusIcon" />
        </b-button>
      </b-col> -->
    </b-row>
    <b-table
      hover
      responsive="sm"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :busy="loading"
      show-empty
    >
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            v-if="data.item.icon_url"
            variant="primary"
            :src="data.item.icon_url"
            class="mr-1"
            size="sm"
          ></b-avatar>
          <div>
            <strong @click="showDetail(data.item.id)">{{ data.item.name }}</strong>
          </div>
        </div>
      </template>

      <template #cell(status)="data">
        <StatusTransationBadge
          :status="data.item.status"
          :status_text="data.item.status_text"
        />
      </template>

      <template #cell(exchange_rate)="data">
        <div v-if="data.item.exchange_rate">Compra: {{data.item.exchange_rate.rate_buy}} <br /> Venta: {{data.item.exchange_rate.rate_sale }}</div>
        <div v-else>S/T</div>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <feather-icon icon="SettingsIcon" />
          </template>
          <b-dropdown-item @click="showDetail(data.item.id)"
            >Ver detalle</b-dropdown-item
          >
          <b-dropdown-item v-if="!data.item.more_options" @click="openAddExchangeRateModal(data.item)"
            >Editar tasa de cambio</b-dropdown-item>
        </b-dropdown>

      </template>

      <template #table-busy>
        <div class="text-center text-primary my-5 pt-3">
          <b-spinner type="grow" class="align-middle"></b-spinner>
          <strong class="ml-1">Cargando</strong>
        </div>
      </template>

      <template #empty>
        <div
          class="text-center my-5 pt-3 d-flex w-100 justify-content-center align-items-center"
        >
          <feather-icon icon="AlertCircleIcon" size="1.5x" />
          <strong class="ml-1">No hay registros para mostrar</strong>
        </div>
      </template>
    </b-table>
    <b-row v-show="items.length > 0 && !loading"
      ><b-col md="2" sm="12" class="d-none d-sm-block">
        <b-form-group class="mb-0">
          <label class="d-inline-block mr-50">Por página</label>
          <b-form-select
            id="perPageSelect"
            :value="perPage"
            size="sm"
            :options="pageOptions"
            @change="perPageChange"
            style="width: auto"
          />
        </b-form-group>
      </b-col>
      <b-col v-show="totalRows > perPage" md="10" sm="12">
        <b-pagination
          striped
          hover
          responsive
          first-number
          last-number
          :value="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
          @change="changePage"
        />
      </b-col>
    </b-row>

    <b-sidebar no-header v-model="showAddExchangeRateModal" right backdrop shadow body-class="colorBackground" width="500px">
      <add-edit-exchange-rate-form
        :item="exchangeRate"
        :code="exchangeRateCode"
        :paymentMethodId="paymentMethodId"
        :currencyId="currencyId"
        :namespaceModel="namespaceModel"
        :modelId="modelId"
        @saved="savedExchangeRate"
      />
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BCol,
  BPagination,
  BRow,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BButton,
  BSidebar,
  VBToggle,
  BSpinner,
  BAvatar,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

import { confirmAlert } from '@/helpers/utils'
import numberMixins from '@/mixins/numberMixins'
import StatusTransationBadge from '@/components/StatusTransationBadge'
import UserBadge from '@/components/UserBadge'

import AddEditExchangeRateForm from '@/components/AddEditExchangeRateForm'

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BCol,
    BPagination,
    BRow,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BSidebar,
    BSpinner,
    StatusTransationBadge,
    UserBadge,
    BAvatar,
    AddEditExchangeRateForm
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [numberMixins],
  data() {
    return {
      showAddExchangeRateModal: false,
      paymentMethodId: null, // payment method id
      currencyId: null, // currency id
      namespaceModel: null,
      modelId: null,
      exchangeRate: null,
      exchangeRateCode: null,
      open: false,
      searchValue: '',
      item: null,
      fields: [
        {
          key: 'name',
          label: 'Metodo',
          sortable: true,
        },
        // {
        //   key: 'code',
        //   label: 'Pais',
        //   sortable: true,
        // },
        {
          key: 'format_code',
          label: 'Codigo Moneda',
          sortable: true,
        },
        {
          key: 'exchange_rate',
          label: 'Tasas de cambio',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'Acciones',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.rechargeMethods.items,
      perPage: (state) => state.rechargeMethods.perPage,
      currentPage: (state) => state.rechargeMethods.currentPage,
      totalRows: (state) => state.rechargeMethods.totalRows,
      pageOptions: (state) => state.rechargeMethods.pageOptions,
      searchTerm: (state) => state.rechargeMethods.searchTerm,
      loading: (state) => state.rechargeMethods.loading,
    }),
  },
  methods: {
    openAddExchangeRateModal(item) {
      this.namespaceModel = item.namespace_model
      this.modelId = item.id
      this.paymentMethodId = item.id
      this.currencyId = item.currency.id
      this.exchangeRate = { name: item.name, ...item.exchange_rate }
      this.exchangeRateCode = item.code
      this.showAddExchangeRateModal = true
    },
    savedExchangeRate() {
      this.showAddExchangeRateModal = false
      this.getItems()
    },
    showDetail(id) {
      this.$router.push({ name: 'recharge-methods.detail', params: { id } })
    },
    showDocumentDetail(document) {
      this.$router.push({
        name: 'document-detail',
        params: { id: document.id, type: 'invoice' },
      })
    },
    create() {
      this.$router.push({
        name: 'document',
        params: { type: 'invoice', action: 'new' },
      })
    },
    edit(item) {
      this.item = item
      this.openSidebar()
    },
    async deleteItem(item) {
      const res = await confirmAlert('¿Está seguro de eliminar este registro?')
      if (res.value) {
        const r = await this.$store.dispatch('rechargeMethods/delete', {
          id: item.id,
        })
        this.$swal('Eliminado', 'El registro ha sido eliminado', 'success')
      }
    },
    openSidebar(open = true) {
      this.open = open
    },
    getItems(page = 1) {
      this.$store.dispatch('rechargeMethods/get', {
        currentPage: page,
      })
    },
    changePage(page) {
      this.getItems(page)
    },
    perPageChange(perPage) {
      this.$store.dispatch('rechargeMethods/perPageChange', perPage)
      this.getItems()
    },
    search(value) {
    },
  },
  mounted() {
    this.getItems()
  },
  watch: {
    searchValue(value) {
      this.$store.dispatch('rechargeMethods/searchTermChange', value)
      this.getItems()
    },
  },
}
</script>

<style></style>
