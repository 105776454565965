<template>
  <span :class="['badge', pre_approved && status === 'in_review' ? 'badge-warning' : status]">
    {{ pre_approved && status === 'in_review' ? 'Pre-Aprobada' : status_text }}
  </span>
</template>
<script>
export default {
  name: 'StatusTransation',
  props: {
    pre_approved: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: 'pending',
    },
    status_text: {
      type: String,
      default: 'Pendiente',
    },
  },
  computed: {
    statusClass() {
      return {
        'badge-success': this.status === 'completed',
        'badge-danger': this.status === 'rejected',
        'badge-warning': this.status === 'pending',
        'badge-warning': this.status === 'in_review',
        'badge-danger': this.status === 'cancelled',
      }
    },
  },
}
</script>
<style scoped>
.completed {
  background-color: #6bf182;
  color: #05971d;
}

.rejected {
  background-color: #ff7a7a;
  color: #a70000;
}

.pending {
  background-color: #ffd14f;
  color: #bc9d12;
}

.in_review {
  background-color: #ffd14f;
  color: #bc9d12;
}

.cancelled {
  background-color: #ff7a7a;
  color: #a70000;
}

.badge {
  padding: 0.8rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 25px;
  text-transform: capitalize;
}
</style>
